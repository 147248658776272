.orgchart.myChart {
    background-image: linear-gradient(
        90deg,
        rgba(33, 90, 136, 0.15) 10%,
        rgba(0, 0, 0, 0) 10%
      ),
      linear-gradient(rgba(33, 90, 136, 0.15) 10%, rgba(0, 0, 0, 0) 10%) !important;
  }
  
  .orgchart.myChart > ul > li > ul li::before {
    border-top-color: #215a88;
  }
  
  .orgchart.myChart > ul > li > ul li .oc-node::before,
  .orgchart.myChart ul li .oc-node:not(:only-child)::after {
    background-color: #c92238;
  }
  
  .orgchart.myChart .oc-node .position {
    box-sizing: border-box;
    background-color: #F50057;
    color: #fff;
    width: 110px;
    height: 35px;
    padding: 2px;
    -webkit-border-top-left-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    -moz-border-radius-topleft: 10px;
    -moz-border-radius-topright: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .orgchart.myChart .oc-node .fullname {
    box-sizing: border-box;
    color: #215a88;
    background-color: #fff;
    width: 110px;
    height: 65px;
    padding: 2px;
    border: 1px solid #215a88;
    -webkit-border-bottom-right-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-radius-bottomright: 10px;
    -moz-border-radius-bottomleft: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  